export default [
  {"code": "82", "ko": "대한민국", "en": "Korea"},
  {"code": "93", "ko": "아프가니스탄", "en": "Afghanistan"},
  {"code": "355", "ko": "알바니아", "en": "Albania"},
  {"code": "213", "ko": "알제리", "en": "Algeria"},
  {"code": "1684", "ko": "미국령사모아", "en": "American Samoa"},
  {"code": "376", "ko": "안도라", "en": "Andorra"},
  {"code": "244", "ko": "앙골라", "en": "Angola"},
  {"code": "1264", "ko": "영국령 앵귈라", "en": "Anguilla"},
  {"code": "1268", "ko": "앤티가바부다", "en": "Antigua & Barbuda"},
  {"code": "54", "ko": "아르헨티나", "en": "Argentina"},
  {"code": "374", "ko": "아르메니아", "en": "Armenia"},
  {"code": "297", "ko": "아루바", "en": "Aruba"},
  {"code": "61", "ko": "호주", "en": "Australia"},
  {"code": "43", "ko": "오스트리아", "en": "Austria"},
  {"code": "994", "ko": "아제르바이잔", "en": "Azerbaijan"},
  {"code": "1242", "ko": "바하마", "en": "Bahamas"},
  {"code": "973", "ko": "바레인", "en": "Bahrain"},
  {"code": "880", "ko": "방글라데시", "en": "Bangladesh"},
  {"code": "1246", "ko": "바베이도스", "en": "Barbados"},
  {"code": "375", "ko": "벨라루스", "en": "Belarus"},
  {"code": "32", "ko": "벨기에", "en": "Belgium"},
  {"code": "501", "ko": "벨리즈", "en": "Belize"},
  {"code": "229", "ko": "베냉", "en": "Benin"},
  {"code": "1441", "ko": "영국령 버뮤다", "en": "Bermuda"},
  {"code": "975", "ko": "부탄", "en": "Bhutan"},
  {"code": "591", "ko": "볼리비아", "en": "Bolivia"},
  {"code": "387", "ko": "보스니아 헤르체고비나", "en": "Bosnia and Herzegovina"},
  {"code": "267", "ko": "보츠와나", "en": "Botswana"},
  {"code": "55", "ko": "브라질", "en": "Brazil"},
  {"code": "1340", "ko": "영국령 버진아일랜드", "en": "British Virgin Islands"},
  {"code": "673", "ko": "브루나이 다루살람", "en": "Brunei Darussalam"},
  {"code": "359", "ko": "불가리아", "en": "Bulgaria"},
  {"code": "226", "ko": "부르키나파소", "en": "Burkina Faso"},
  {"code": "257", "ko": "부룬디", "en": "Burundi"},
  {"code": "855", "ko": "캄보디아", "en": "Cambodia"},
  {"code": "237", "ko": "카메룬", "en": "Cameroon"},
  {"code": "238", "ko": "카보베르데", "en": "Cape Verde"},
  {"code": "1345", "ko": "영국령 케이맨 제도", "en": "Cayman Islands"},
  {"code": "236", "ko": "중앙아프리카 공화국", "en": "Central African Republic"},
  {"code": "235", "ko": "차드", "en": "Chad"},
  {"code": "56", "ko": "칠레", "en": "Chile"},
  {"code": "86", "ko": "중국", "en": "China"},
  {"code": "682", "ko": "쿡 제도", "en": "Cocos Keeling Islands(Cook Islands)"},
  {"code": "57", "ko": "콜롬비아", "en": "Colombia"},
  {"code": "242", "ko": "콩고", "en": "Congo"},
  {"code": "506", "ko": "코스타리카", "en": "Costa Rica"},
  {"code": "225", "ko": "코트디부아르", "en": "Côte d'Ivoire"},
  {"code": "385", "ko": "크로아티아", "en": "Croatia"},
  {"code": "53", "ko": "쿠바", "en": "Cuba"},
  {"code": "357", "ko": "키프로스", "en": "Cyprus"},
  {"code": "420", "ko": "체코", "en": "Czechia"},
  {"code": "243", "ko": "콩고 민주 공화국", "en": "Democratic Republic of the Congo"},
  {"code": "45", "ko": "덴마크", "en": "Denmark"},
  {"code": "253", "ko": "지부티", "en": "Djibouti"},
  {"code": "1767", "ko": "도미니카 연방", "en": "Dominica"},
  {"code": "1809, 1829, 1849", "ko": "도미니카 공화국", "en": "Dominican Republic"},
  {"code": "593", "ko": "에콰도르", "en": "Ecuador"},
  {"code": "20", "ko": "이집트", "en": "Egypt"},
  {"code": "503", "ko": "엘살바도르", "en": "El Salvador"},
  {"code": "291", "ko": "에리트레아", "en": "Eritrea"},
  {"code": "240", "ko": "적도 기니", "en": "Equatorial Guinea"},
  {"code": "372", "ko": "에스토니아", "en": "Estonia"},
  {"code": "251", "ko": "에티오피아", "en": "Ethiopia"},
  {"code": "500", "ko": "영국령 포클랜드 제도", "en": "Falkland Islands"},
  {"code": "298", "ko": "페로 제도", "en": "Faroe Islands"},
  {"code": "679", "ko": "피지", "en": "Fiji"},
  {"code": "358", "ko": "핀란드", "en": "Finland"},
  {"code": "33", "ko": "프랑스", "en": "France"},
  {"code": "594", "ko": "프랑스령 기아나", "en": "French Guiana"},
  {"code": "689", "ko": "프랑스령 폴리네시아", "en": "French Polynesia"},
  {"code": "241", "ko": "가봉", "en": "Gabon"},
  {"code": "220", "ko": "감비아", "en": "Gambia"},
  {"code": "995", "ko": "조지아", "en": "Georgia"},
  {"code": "49", "ko": "독일", "en": "Germany"},
  {"code": "233", "ko": "가나", "en": "Ghana"},
  {"code": "350", "ko": "지브롤터", "en": "Gibraltar"},
  {"code": "30", "ko": "그리스", "en": "Greece"},
  {"code": "299", "ko": "그린란드", "en": "Greenland"},
  {"code": "1473", "ko": "그레나다", "en": "Grenada"},
  {"code": "590", "ko": "프랑스령 과들루프", "en": "Guadeloupe"},
  {"code": "1671", "ko": "괌", "en": "Guam"},
  {"code": "502", "ko": "과테말라", "en": "Guatemala"},
  {"code": "224", "ko": "기니", "en": "Guinea"},
  {"code": "245", "ko": "기니비사우", "en": "Guinea-Bissau"},
  {"code": "592", "ko": "가이아나", "en": "Guyana"},
  {"code": "509", "ko": "아이티", "en": "Haiti"},
  {"code": "504", "ko": "온두라스", "en": "Honduras"},
  {"code": "852", "ko": "홍콩", "en": "Hong-Kong"},
  {"code": "36", "ko": "헝가리", "en": "Hungary"},
  {"code": "354", "ko": "아이슬란드", "en": "Iceland"},
  {"code": "91", "ko": "인도", "en": "India"},
  {"code": "62", "ko": "인도네시아", "en": "Indonesia"},
  {"code": "98", "ko": "이란", "en": "Iran"},
  {"code": "964", "ko": "이라크", "en": "Iraq"},
  {"code": "353", "ko": "아일랜드", "en": "Ireland"},
  {"code": "972", "ko": "이스라엘", "en": "Israel"},
  {"code": "39", "ko": "이탈리아", "en": "Italy"},
  {"code": "1876", "ko": "자메이카", "en": "Jamaica"},
  {"code": "81", "ko": "일본", "en": "Japan"},
  {"code": "962", "ko": "요르단", "en": "Jordan"},
  {"code": "254", "ko": "케냐", "en": "Kenya"},
  {"code": "686", "ko": "키리바시", "en": "Kiribati"},
  {"code": "965", "ko": "쿠웨이트", "en": "Kuwait"},
  {"code": "996", "ko": "키르기스스탄", "en": "Kyrgyzstan"},
  {"code": "856", "ko": "라오스", "en": "Laos"},
  {"code": "371", "ko": "라트비아", "en": "Latvia"},
  {"code": "961", "ko": "레바논", "en": "Lebanon"},
  {"code": "266", "ko": "레소토", "en": "Lesotho"},
  {"code": "231", "ko": "라이베리아", "en": "Liberia"},
  {"code": "218", "ko": "리비아", "en": "Libya"},
  {"code": "423", "ko": "리히텐슈타인", "en": "Liechtenstein"},
  {"code": "370", "ko": "리투아니아", "en": "Lithuania"},
  {"code": "352", "ko": "룩셈부르크", "en": "Luxembourg"},
  {"code": "853", "ko": "마카오", "en": "Macau"},
  {"code": "261", "ko": "마다가스카르", "en": "Madagascar"},
  {"code": "265", "ko": "말라위", "en": "Malawi"},
  {"code": "60", "ko": "말레이시아", "en": "Malaysia"},
  {"code": "960", "ko": "몰디브", "en": "Maldives"},
  {"code": "223", "ko": "말리", "en": "Mali"},
  {"code": "356", "ko": "몰타", "en": "Malta"},
  {"code": "692", "ko": "마셜 제도 공화국", "en": "Marshall Islands"},
  {"code": "596", "ko": "프랑스령 마르티니크", "en": "Martinique"},
  {"code": "222", "ko": "모리타니", "en": "Mauritania"},
  {"code": "230", "ko": "모리셔스", "en": "Mauritius"},
  {"code": "269", "ko": "마요트/코모로", "en": "Mayotte/Comoros"},
  {"code": "52", "ko": "멕시코", "en": "Mexico"},
  {"code": "691", "ko": "미크로네시아", "en": "Micronesia"},
  {"code": "373", "ko": "몰도바", "en": "Moldova"},
  {"code": "377", "ko": "모나코", "en": "Monaco"},
  {"code": "976", "ko": "몽골", "en": "Mongolia"},
  {"code": "382", "ko": "몬테네그로", "en": "Montenegro"},
  {"code": "1664", "ko": "영국령 몬트세랫", "en": "Montserrat"},
  {"code": "212", "ko": "모로코", "en": "Morocco"},
  {"code": "258", "ko": "모잠비크", "en": "Mozambique"},
  {"code": "95", "ko": "미얀마", "en": "Myanmar"},
  {"code": "264", "ko": "나미비아", "en": "Namibia"},
  {"code": "674", "ko": "나우루", "en": "Nauru"},
  {"code": "599", "ko": "네덜란드령 안틸레스/퀴라소", "en": "Nederlandse Antillen/Curaçao"},
  {"code": "977", "ko": "네팔", "en": "Nepal"},
  {"code": "31", "ko": "네덜란드", "en": "Netherlands"},
  {"code": "687", "ko": "뉴칼레도니아", "en": "New Caledonia"},
  {"code": "64", "ko": "뉴질랜드", "en": "New Zealand"},
  {"code": "505", "ko": "니카라과", "en": "Nicaragua"},
  {"code": "227", "ko": "니제르", "en": "Niger"},
  {"code": "234", "ko": "나이지리아", "en": "Nigeria"},
  {"code": "389", "ko": "북마케도니아", "en": "North Macedonia"},
  {"code": "1670", "ko": "미국령 북마리아나 제도", "en": "Northern Marianas"},
  {"code": "47", "ko": "노르웨이", "en": "Norway"},
  {"code": "968", "ko": "오만", "en": "Oman"},
  {"code": "92", "ko": "파키스탄", "en": "Pakistan"},
  {"code": "680", "ko": "팔라우", "en": "Palau"},
  {"code": "970", "ko": "팔레스타인", "en": "Palestine"},
  {"code": "507", "ko": "파나마", "en": "Panama"},
  {"code": "675", "ko": "파푸아뉴기니", "en": "Papua New Guinea"},
  {"code": "595", "ko": "파라과이", "en": "Paraguay"},
  {"code": "51", "ko": "페루", "en": "Peru"},
  {"code": "63", "ko": "필리핀", "en": "Philippines"},
  {"code": "48", "ko": "폴란드", "en": "Poland"},
  {"code": "351", "ko": "포르투갈", "en": "Portugal"},
  {"code": "1787, 1939", "ko": "푸에르토리코", "en": "Puerto Rico"},
  {"code": "974", "ko": "카타르", "en": "Qatar"},
  {"code": "383", "ko": "코소보 공화국", "en": "Republic of Kosovo"},
  {"code": "262", "ko": "프랑스령 레위니옹", "en": "Réunion Island"},
  {"code": "40", "ko": "루마니아", "en": "Romania"},
  {"code": "7", "ko": "러시아 / 카자흐스탄", "en": "Russia / Kazakhstan"},
  {"code": "250", "ko": "르완다", "en": "Rwanda"},
  {"code": "239", "ko": "상투메프린시페", "en": "S. Tomé & Principe"},
  {"code": "290", "ko": "영국령 세인트헬레나", "en": "Saint Helena"},
  {"code": "685", "ko": "사모아", "en": "Samoa"},
  {"code": "378", "ko": "산마리노", "en": "San Marino"},
  {"code": "966", "ko": "사우디아라비아", "en": "Saudi Arabia"},
  {"code": "221", "ko": "세네갈", "en": "Senegal"},
  {"code": "381", "ko": "세르비아", "en": "Serbia"},
  {"code": "248", "ko": "세이셸", "en": "Seychelles"},
  {"code": "232", "ko": "시에라리온", "en": "Sierra Leone"},
  {"code": "65", "ko": "싱가포르", "en": "Singapore"},
  {"code": "421", "ko": "슬로바키아", "en": "Slovakia"},
  {"code": "386", "ko": "슬로베니아", "en": "Slovenia"},
  {"code": "677", "ko": "솔로몬 제도", "en": "Solomon Islands"},
  {"code": "252", "ko": "소말리아", "en": "Somalia"},
  {"code": "27", "ko": "남아프리카 공화국", "en": "South Africa"},
  {"code": "211", "ko": "남수단 공화국", "en": "South Sudan"},
  {"code": "34", "ko": "스페인", "en": "Spain"},
  {"code": "94", "ko": "스리랑카", "en": "Sri Lanka"},
  {"code": "1869", "ko": "세인트키츠네비스", "en": "St. Kitts and Nevis"},
  {"code": "1758", "ko": "세인트루시아", "en": "St. Lucia"},
  {"code": "508", "ko": "프랑스령 생피에르 미클롱", "en": "St. Pierre & Miquelon"},
  {"code": "1784", "ko": "세인트 빈센트 그레나딘", "en": "St. Vincent and the Grenadines"},
  {"code": "249", "ko": "수단", "en": "Sudan"},
  {"code": "597", "ko": "수리남", "en": "Suriname"},
  {"code": "268", "ko": "스와질랜드", "en": "Swaziland"},
  {"code": "46", "ko": "스웨덴", "en": "Sweden"},
  {"code": "41", "ko": "스위스", "en": "Switzerland"},
  {"code": "963", "ko": "시리아", "en": "Syria"},
  {"code": "886", "ko": "대만", "en": "Taiwan"},
  {"code": "992", "ko": "타지키스탄", "en": "Tajikistan"},
  {"code": "255", "ko": "탄자니아", "en": "Tanzania"},
  {"code": "66", "ko": "태국", "en": "Thailand"},
  {"code": "670", "ko": "동티모르", "en": "Timor-Leste"},
  {"code": "228", "ko": "토고", "en": "Togo"},
  {"code": "676", "ko": "통가", "en": "Tonga"},
  {"code": "1868", "ko": "트리니다드 토바고", "en": "Trinidad & Tobago Rep."},
  {"code": "216", "ko": "튀니지", "en": "Tunisia"},
  {"code": "90", "ko": "튀르키예", "en": "Turkey"},
  {"code": "993", "ko": "투르크메니스탄", "en": "Turkmenistan"},
  {"code": "1649", "ko": "영국령 커크스케이커스제도", "en": "Turks & Caicos Is."},
  {"code": "256", "ko": "우간다", "en": "Uganda"},
  {"code": "380", "ko": "우크라이나", "en": "Ukraine"},
  {"code": "971", "ko": "아랍에미리트연합", "en": "United Arab Emirates"},
  {"code": "44", "ko": "영국", "en": "United Kingdom"},
  {"code": "1", "ko": "미국/캐나다", "en": "United States / Canada"},
  {"code": "598", "ko": "우루과이", "en": "Uruguay"},
  {"code": "998", "ko": "우즈베키스탄", "en": "Uzbekistan"},
  {"code": "678", "ko": "바누아투", "en": "Vanuatu"},
  {"code": "58", "ko": "베네수엘라", "en": "Venezuela"},
  {"code": "84", "ko": "베트남", "en": "Vietnam"},
  {"code": "1284", "ko": "미국령 버진아일랜드", "en": "Virgin Islands(US)"},
  {"code": "681", "ko": "프랑스령 월리스 푸투나 제도", "en": "Wallis and Futuna"},
  {"code": "967", "ko": "예멘", "en": "Yemen"},
  {"code": "260", "ko": "잠비아", "en": "Zambia"},
  {"code": "263", "ko": "짐바브웨", "en": "Zimbabwe"}
];