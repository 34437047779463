<template>
  <div class="my-page-investor-account-info my-page" :class="{skeleton:!state.loaded}">
    <div class="wrapper">
      <ul class="nav">
        <li class="nav-item" v-for="(m, idx) in state.menus" :key="idx" v-show="m.name === 'company' ? ['I', 'C'].includes($store.state.account.memberType) : true">
          <router-link :to="m.path" :class="[$route.path === m.path ? 'active btn-bordered-point': 'btn-default']" class="btn">{{ m.title }}</router-link>
        </li>
      </ul>
      <div class="content">
        <template v-if="state.menu.name === 'member'">
          <div class="row">
            <div class="form-group col-md-6">
              <label :for="`${component.name}MemberId`" class="subject font-sm">
                <span>아이디</span>
                <i class="help fa fa-question-circle-o" title="도움말" data-text="회원가입을 하신 후에는 아이디를 변경하실 수 없어요."></i>
              </label>
              <input :id="`${component.name}MemberId`" type="text" class="form-control border-focus-point font-sm" placeholder="ex) example@domain.com" v-model="state.form.memberId" disabled/>
            </div>
            <div class="form-group col-md-6">
              <label class="subject font-sm">패스워드</label>
              <router-link to="/mypage/account/password" class="btn btn-default border-form btn-block border-form font-sm">변경하기</router-link>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label :for="`${component.name}MemberName`" class="subject font-sm">
                <span class="color-point">*</span>
                <span> 이름</span>
              </label>
              <input :id="`${component.name}MemberName`" type="text" class="form-control border-focus-point font-sm" placeholder="ex) 오마이" v-model="state.form.memberName"/>
            </div>
            <div class="form-group col-md-6">
              <label class="subject font-sm" :for="`${component.name}Birth`">생년월일</label>
              <Date :id="`${component.name}Birth`" class="font-sm" :value.sync="state.form.birth" placeholder="ex) 20220429"/>
            </div>
          </div>
          <div class="row mobile">
            <div class="form-group col-md-6">
              <label class="subject font-sm" :for="`${component.name}Mobile`">
                <span class="color-point">*</span>
                <span> 휴대전화</span>
              </label>
              <MyPhone :id="`${component.name}Mobile`" :value.sync="state.form.mobile" placeholder="ex) 01012345678" v-if="state.loaded"/>
              <input type="text" class="form-control" v-else/>
            </div>
            <div class="form-group col-md-6">
              <label class="subject font-sm" :for="`${component.name}Email`">
                <span class="color-point">*</span>
                <span> 이메일</span>
              </label>
              <input :id="`${component.name}Email`" type="email" class="form-control border-focus-point font-sm" placeholder="ex) example@domain.com" v-model="state.form.email"/>
            </div>
          </div>
          <div class="form-group">
            <label class="subject font-sm" :for="`${component.name}MemberPost`">주소</label>
            <Address componentNameSuffix="Default" :id="`${component.name}MemberPost`" :zoneCode.sync="state.form.memberPost" :address1.sync="state.form.memberAddr1" :address2.sync="state.form.memberAddr2"/>
          </div>
          <div class="form-group">
            <label class="subject font-sm" :for="`${component.name}Post`">기본 배송지</label>
            <a class="float-right color-anchor pointer mt-1 font-xs" @click="openShippingAddressModal()">배송지 목록</a>
            <div class="row">
              <div class="col-lg-4 pr-lg-1">
                <select class="font-sm border-focus-point form-control mb-10" v-model="state.shipping.addressName" @change="onAddressSelectChange()" v-if="state.address.type === 'select'">
                  <option :value="a" v-for="(a, idx) in state.address.list" :key="idx">{{ a }}</option>
                </select>
                <input type="text" class="form-control border-focus-point font-sm mb-10" :id="`${component.name}ShippingAddressName`" placeholder="ex) 배송지명" v-model="state.shipping.addressName" v-else/>
              </div>
              <div class="col-lg-4">
                <input type="text" class="form-control border-focus-point font-sm mb-10" :id="`${component.name}ShippingName`" placeholder="ex) 홍길동" v-model="state.shipping.name"/>
              </div>
              <div class="col-lg-4 pl-lg-1">
                <Phone class="mb-10" :id="`${component.name}ShippingMobile`" :value.sync="state.shipping.mobile" placeholder="ex) 01012345678"/>
              </div>
            </div>
            <Address componentNameSuffix="Shipping" :id="`${component.name}Post`" :zoneCode.sync="state.shipping.post" :address1.sync="state.shipping.addr1" :address2.sync="state.shipping.addr2"/>
          </div>
        </template>
        <template v-else-if="state.menu.name === 'password'">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="subject font-sm" :for="`${component.name}NewPassword`">
                  <span class="color-point">*</span>
                  <span> 변경할 패스워드</span>
                </label>
                <input type="password" :id="`${component.name}NewPassword`" class="form-control border-focus-point font-sm" v-model="state.form.passwd" autocomplete="off"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="subject font-sm" :for="`${component.name}NewPasswordRepeat`">
                  <span class="color-point">*</span>
                  <span> 변경할 패스워드 재입력</span>
                </label>
                <input type="password" :id="`${component.name}NewPasswordRepeat`" class="form-control border-focus-point font-sm" autocomplete="off"/>
              </div>
            </div>
          </div>
        </template>
        <template v-if="state.menu.name === 'company'">
          <div class="row">
            <div class="form-group col-md-6">
              <label class="subject font-sm" :for="`${component.name}CorporateName`">
                <span class="color-point">*</span>
                <span> 기업명</span>
              </label>
              <input :id="`${component.name}CorporateName`" type="text" class="form-control border-focus-point font-sm" placeholder="ex) 오마이컴퍼니" :value="state.form.corporateName" disabled/>
            </div>
            <div class="form-group col-md-6">
              <label class="subject font-sm" :for="`${component.name}CeoName`">대표자명</label>
              <input :id="`${component.name}CeoName`" type="text" class="form-control border-focus-point font-sm" placeholder="ex) 오마이" v-model="state.form.ceoName"/>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label class="subject font-sm" :for="`${component.name}BusinessNum`">
                <span class="color-point">*</span>
                <span> 사업자등록번호</span>
              </label>
              <input :id="`${component.name}BusinessNum`" type="text" class="form-control border-focus-point font-sm" placeholder="ex) 209-81-56911" :value="state.form.businessNum" disabled/>
            </div>
            <div class="form-group col-md-6">
              <label class="subject font-sm" :for="`${component.name}BusinessTel`">
                <span>대표전화</span>
              </label>
              <input :id="`${component.name}BusinessTel`" type="text" class="form-control border-focus-point font-sm" placeholder="ex) 02-123-7981" v-model="state.form.businessTel"/>
            </div>
          </div>
          <div class="row" v-if="state.form.memberType === 'C'">
            <div class="form-group col-md-6">
              <label class="subject font-sm" :for="`${component.name}CorporateNum`">
                <span class="color-point">*</span>
                <span> 법인등록번호</span>
              </label>
              <input :id="`${component.name}CorporateNum`" type="text" class="form-control border-focus-point font-sm" placeholder="ex) 01123217891" :value="state.form.corporateNum" disabled/>
            </div>
            <div class="form-group col-md-6">
              <label class="subject font-sm" :for="`${component.name}businessFax`">팩스</label>
              <input :id="`${component.name}businessFax`" type="text" class="form-control border-focus-point font-sm" placeholder="ex) 027982142" v-model="state.form.businessFax"/>
            </div>
          </div>
          <div class="form-group">
            <label class="subject font-sm" :for="`${component.name}BusinessAddress`">
              <span class="color-point">*</span>
              <span> 기업 주소</span>
            </label>
            <Address :for="`${component.name}BusinessAddress`" :zoneCode.sync="state.form.businessPost" :address1.sync="state.form.businessAddr1" :address2.sync="state.form.businessAddr2"/>
          </div>
          <div class="form-group">
            <label class="subject font-sm" :for="`${component.name}Homepage`">홈페이지 주소</label>
            <input :id="`${component.name}Homepage`" type="text" class="form-control border-focus-point font-sm" placeholder="ex) example.com" v-model="state.form.homepage"/>
          </div>
        </template>
        <template v-if="state.menu.name === 'channel'">
          <div class="channel">
            <div class="setting">
              <div class="title">
                <span>공개 설정</span>
              </div>
              <div class="wrapper row">
                <div class="col-lg-6">
                  <div class="privacy action">
                    <span>펀딩 프로젝트 공개</span>
                    <label class="switch">
                      <input type="checkbox" :checked="state.form.supportOpenFlag === 'Y'" @change="ChangeSupportsOpenSetting()">
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group intro">
              <label class="subject title" :for="`${component.name}Introduction`">
                <span>채널 소개</span>
                <span class="intro-length">{{ `(${state.form.introduction ? state.form.introduction.trim().length : 0} / 170)` }}</span>
              </label>
              <textarea :id="`${component.name}Introduction`" class="form-control border-focus-point font-sm" placeholder="소개 내용을 170자 이내로 입력해주세요." v-model="state.form.introduction" maxlength="170"></textarea>
            </div>
          </div>
        </template>
        <template v-if="state.menu.name === 'etc'">
          <div class="row">
            <div class="form-group col-md-2">
              <label class="subject font-sm" :for="`${component.name}SmsRecv`">
                <span>SMS 수신</span>
              </label>
              <select :id="`${component.name}SmsRecv`" class="form-control border-focus-point font-sm" v-model="state.form.smsRecv">
                <option value="Y">동의</option>
                <option value="N">거부</option>
              </select>
            </div>
            <div class="form-group col-md-2">
              <label class="subject font-sm" :for="`${component.name}EmailRecv`">
                <span>이메일 수신</span>
              </label>
              <select :id="`${component.name}EmailRecv`" class="form-control border-focus-point font-sm" v-model="state.form.emailRecv">
                <option value="Y">동의</option>
                <option value="N">거부</option>
              </select>
            </div>
            <div class="form-group col-md-8">
              <label class="subject font-sm" :for="`${component.name}NftWalletAddress`">
                <span>NFT 지갑주소</span>
              </label>
              <div class="input-group font-sm">
                <input type="text" :id="`${component.name}NftWalletAddress`" class="border-focus-point font-sm form-control" placeholder="인증 받기 버튼을 클릭해주세요." :value="state.form.nftWalletAddress" @click="openNftModal()" readonly/>
                <div class="input-group-append">
                  <button class="btn btn-secondary font-xs pl-3 pr-3" @click="openNftModal()">인증 받기</button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="action">
          <div class="wrapper">
            <a class="withdrawal font-xs" @click="$store.commit('openModal', { name: 'Leave' })">회원 탈퇴</a>
            <button class="btn btn-point font-sm" @click="submit()">저장하기</button>
          </div>
        </div>
      </div>
      <!--      <div class="guide side">-->
      <!--        <ul class="tight font-sm">-->
      <!--          <li>-->
      <!--            <b>안내해드립니다.</b>-->
      <!--          </li>-->
      <!--          <li>별(*) 표시는 필수 입력 항목이에요</li>-->
      <!--          <li>아래 버튼을 클릭하시면 계정 정보 관련 이용가이드를 보실 수 있어요.</li>-->
      <!--        </ul>-->
      <!--        <div class="link">-->
      <!--          <a class="btn btn-block btn-secondary font-xs" href="https://ohmycompanycom.notion.site/e9b0a726c6804a2a8ff5ae89cde5386b" target="_blank" rel="noopener noreferrer">이용가이드 바로가기</a></div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, set} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import Date from "@/components/Date";
import Phone from "@/components/Phone";
import Address from "@/components/Address";
import router from "@/scripts/router";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import Number from "@/components/Number";
import MyPhone from "@/components/MyPhone";

function Component(initialize) {
  this.name = "pageMyPageAccount";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {MyPhone, Number, Date, Phone, Address},
  props: {
    menu: Object
  },
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "계정 정보");
      state.menu = state.menus[0];

      for (let i in state.menus) {
        if (state.menus[i].path === router.app.$route.path) {
          state.menu = state.menus[i];
          break;
        }
      }

      load();
    });

    const state = reactive({
      loaded: false,
      menu: {
        name: "",
        title: "",
        path: ""
      },
      menus: [
        {name: "member", title: "회원 정보", path: "/mypage/account"},
        {name: "password", title: "패스워드", path: "/mypage/account/password"},
        {name: "company", title: "기업 정보", path: "/mypage/account/company"},
        {name: "channel", title: "채널 관리", path: "/mypage/account/channel"},
        {name: "etc", title: "기타", path: "/mypage/account/etc"},
      ],
      form: {
        memberSeq: "",
        memberId: "",
        memberName: "",
        passwd: "",
        memberType: "",
        memberAuth: "",
        email: "",
        mobile: "",
        birth: "",
        memberPost: "",
        memberAddr1: "",
        memberAddr2: "",
        emailRecv: "",
        smsRecv: "",
        introduction: "",
        corporateName: "",
        ceoName: "",
        businessNum: "",
        corporateNum: "",
        homepage: "",
        businessTel: "",
        businessFax: "",
        businessPost: "",
        businessAddr1: "",
        businessAddr2: "",
        pwMisCnt: 0,
        createDate: "",
        updateDate: "",
        leaveFlag: "",
        delFlag: "",
        pwSearchFlag: "",
        joinType: "",
        memberIp: "",
        investStatus: "",
        nftWalletAddress: "",
      },
      shipping: {
        addressSeq: "",
        addressName: "",
        name: "",
        mobile: "",
        post: "",
        addr1: "",
        addr2: "",
        defaultFlag: "Y",
      },
      address: {
        type: "select",
        list: ["집", "회사", "가족", "친구", "직접 입력"],
      },
    });

    const setNftWalletAddress = (nftWalletAddress) => {
      set(state.form, "nftWalletAddress", nftWalletAddress);
    };

    const openNftModal = () => {
      store.commit("openModal", {
        name: "Nft",
        callback: `${component.name}.setNftWalletAddress`
      });
    };

    const submit = () => {
      const args = lib.getRenewed(state.form);

      if (state.menu.name === "member") {
        if (!args.memberName?.trim()) {
          document.getElementById(`${component.name}MemberName`)?.focus();
          return store.commit("setSwingMessage", "이름을 입력해주세요.");
        } else if (!args.mobile?.trim()) {
          document.getElementById(`${component.name}Mobile`)?.focus();
          return store.commit("setSwingMessage", "휴대전화 번호를 입력해주세요.");
        } else if (!args.email?.trim()) {
          document.getElementById(`${component.name}Email`)?.focus();
          return store.commit("setSwingMessage", "이메일 주소를 입력해주세요.");
        }
      }

      if (state.menu.name === "password") {
        const $newPassword = document.getElementById(`${component.name}NewPassword`);
        const $newPasswordRepeat = document.getElementById(`${component.name}NewPasswordRepeat`);

        if (!$newPassword.value) {
          $newPassword.focus();
          return store.commit("setSwingMessage", "변경할 패스워드를 입력해주세요.");
        } else if ($newPassword.value.length < 10 || $newPassword.value.length > 16) {
          $newPassword.focus();
          return store.commit("setSwingMessage", "패스워드는 10자리 이상 16자리 이하로 입력해주세요.");
        } else if (!$newPasswordRepeat.value) {
          $newPasswordRepeat.focus();
          return store.commit("setSwingMessage", "변경할 패스워드를 재입력해주세요.");
        } else if ($newPassword.value !== $newPasswordRepeat.value) {
          $newPasswordRepeat.focus();
          return store.commit("setSwingMessage", "패스워드와 재입력한 패스워드가 일치하지 않습니다.");
        }
      }

      if (state.menu.name === "channel") {
        if (args.introduction) {
          args.introduction = args.introduction.trim();
        }
      }
      // else if (state.menu.name === "company") {
      //   args.businessNum = args.businessNum?.trim();
      //   args.corporateNum = args.corporateNum?.trim();
      //
      //   if (!args.businessNum) {
      //     document.getElementById(`${component.name}BusinessNum`).focus();
      //     return store.commit("setSwingMessage", "사업자등록번호를 입력해주세요.");
      //   } else if (args.businessNum.length !== 10 || !lib.isNumeric(args.businessNum)) {
      //     document.getElementById(`${component.name}BusinessNum`).focus();
      //     return store.commit("setSwingMessage", "사업자등록번호 값이 올바른지 확인해주세요.");
      //   }
      //
      //   if (args.memberType === "C") {
      //     if (!args.corporateNum) {
      //       document.getElementById(`${component.name}CorporateNum`).focus();
      //       return store.commit("setSwingMessage", "법인등록번호를 입력해주세요.");
      //     } else if (args.corporateNum.length !== 13 || !lib.isNumeric(args.corporateNum)) {
      //       document.getElementById(`${component.name}CorporateNum`).focus();
      //       return store.commit("setSwingMessage", "법인등록번호 값이 올바른지 확인해주세요.");
      //     }
      //   }
      // }

      if (state.shipping.addressSeq && (state.shipping.post || state.shipping.addr1 || state.shipping.addr1)) {
        if (!state.shipping.name) {
          document.getElementById(`${component.name}ShippingName`).focus();
          return store.commit("setSwingMessage", "이름을 입력해주세요.");
        } else if (!state.shipping.mobile) {
          document.getElementById(`${component.name}ShippingMobile`).focus();
          return store.commit("setSwingMessage", "휴대전화 번호를 입력해주세요.");
        } else if (!state.shipping.addressName) {
          document.getElementById(`${component.name}ShippingAddressName`).focus();
          return store.commit("setSwingMessage", "배송지명을 입력해주세요.");
        }

        // insert
        http.put(`/api/member/${store.state.account.memberSeq}/address/${state.shipping.addressSeq}`, state.shipping).then(() => {
        }).catch(httpError());
      } else if (!state.shipping.addressSeq && (state.shipping.post || state.shipping.addr1 || state.shipping.addr1)) {
        if (!state.shipping.name) {
          document.getElementById(`${component.name}ShippingName`).focus();
          return store.commit("setSwingMessage", "이름을 입력해주세요.");
        } else if (!state.shipping.mobile) {
          document.getElementById(`${component.name}ShippingMobile`).focus();
          return store.commit("setSwingMessage", "휴대전화 번호를 입력해주세요.");
        } else if (!state.shipping.addressName) {
          document.getElementById(`${component.name}ShippingAddressName`).focus();
          return store.commit("setSwingMessage", "배송지명을 입력해주세요.");
        }

        // update
        http.post(`/api/member/${store.state.account.memberSeq}/address`, state.shipping).then(() => {
          // http.put(`/api/member/${store.state.account.memberSeq}/address/${data.body.addressSeq}/use`).then(() => {
          // }).catch(httpError());
        }).catch(httpError());
      }

      state.loaded = false;
      http.put(`/api/members/${store.state.account.memberSeq}/account`, args).then(() => {
        state.loaded = true;
        load();

        if (state.menu.name === "password") {
          document.getElementById(`${component.name}NewPassword`).value = "";
          document.getElementById(`${component.name}NewPasswordRepeat`).value = "";
        }

        store.commit("checkAccount");
        store.commit("setSwingMessage", "계정 정보를 저장하였습니다.");
      }).catch(httpError(() => {
        state.loaded = true;
      }));
    };

    const load = () => {
      state.loaded = false;
      http.get(`/api/members/${store.state.account.memberSeq}/account`).then(({data}) => {
        state.loaded = true;
        state.form = data.body;
      }).catch(httpError(() => {
        state.loaded = true;
      }));

      http.get(`/api/member/${store.state.account.memberSeq}/default-address`).then(({data}) => {
        if (data.body) {
          state.shipping.addressSeq = data.body.addressSeq;
          state.shipping.addressName = data.body.addressName;
          state.shipping.name = data.body.name;
          state.shipping.mobile = data.body.mobile;
          state.shipping.post = data.body.post;
          state.shipping.addr1 = data.body.addr1;
          state.shipping.addr2 = data.body.addr2;

          if (!state.address.list.includes(state.shipping.addressName)) {
            state.address.type = "input";
          }
        } else {
          state.shipping.addressName = state.address.list[0];
        }
      }).catch(httpError());
    };

    const openShippingAddressModal = () => {
      store.commit("openModal", {
        name: "ShippingAddress",
        params: {
          name: "ShippingAddress",
          size: "md",
        },
        callback: `${component.name}.onShippingAddressSelect`
      });
    };

    const onShippingAddressSelect = (address) => {
      state.shipping.addressSeq = address.addressSeq;
      state.shipping.name = address.name;
      state.shipping.mobile = address.mobile;
      state.shipping.addressName = address.addressName;
      state.shipping.post = address.post;
      state.shipping.addr1 = address.addr1;
      state.shipping.addr2 = address.addr2;
      state.shipping.checkDefaultAddress = address.defaultFlag === "Y";

      if (!state.address.list.includes(state.shipping.addressName)) {
        state.address.type = "input";
      } else {
        state.address.type = "select";
      }
    };

    const onAddressSelectChange = () => {
      if (state.address.list.indexOf(state.shipping.addressName) === state.address.list.length - 1) {
        state.shipping.addressName = "";
        state.address.type = "input";
      }
    };

    const ChangeSupportsOpenSetting = () => {
      return state.form.supportOpenFlag = state.form.supportOpenFlag === "Y" ? "N" : "Y";
    };

    return {component, state, setNftWalletAddress, openNftModal, openShippingAddressModal, onShippingAddressSelect, onAddressSelectChange, submit, ChangeSupportsOpenSetting};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.mypage";

.my-page-investor-account-info {
  position: relative;

  > .wrapper {
    .content {
      > .row {
        .form-group {
          button {
            height: $formHeight;
          }
        }

        textarea {
          min-height: $px170;
          max-width: 100%;
          min-width: 100%;
          padding: $px15;
        }
      }

      .channel {
        font-size: $px14;

        .title {
          margin-bottom: $px10;
        }

        .action {
          height: $px45;
          padding: $px6 $px12;
        }

        > div + div {
          margin: $px30 0;
        }

        > .setting {
          > .wrapper {
            .privacy {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-radius: $px4;
              border: $px1 solid #ced4da;

              .switch {
                position: relative;
                display: inline-block;
                width: $px50;
                height: $px21;
                margin-bottom: 0;

                > input {
                  opacity: 0;
                  width: 0;
                  height: 0;

                  &:checked + .slider {
                    background-color: $colorPoint;

                    &::before {
                      transform: translate($px29, -50%);
                    }
                  }

                  &:focus + .slider {
                    box-shadow: 0 0 $px1 #2196F3;
                  }
                }

                > .slider {
                  cursor: pointer;
                  background-color: #ccc;
                  display: inline-block;
                  position: relative;
                  width: 100%;
                  height: 100%;
                  transition: .4s;

                  &.round {
                    border-radius: $px50;

                    &::before {
                      border-radius: 50%;
                    }
                  }

                  &::before {
                    position: absolute;
                    content: "";
                    height: $px13;
                    width: $px13;
                    left: $px4;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: #fff;
                    transition: 0.18s;
                  }
                }
              }
            }
          }
        }

        > .intro {
          .title {
            .intro-length {
              font-size: $px11;
              padding-left: $px10;
            }
          }
        }
      }
    }

    .action {
      padding-top: $px15;
      text-align: center;

      > .wrapper {
        position: relative;

        .btn {
          padding: $px16 $px31;
        }

        .withdrawal {
          cursor: pointer;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }

  &.skeleton {
    .nav li a {
      @include skeleton;
    }

    .form-group::v-deep {
      .subject {
        @include skeleton;

        span {
          color: transparent !important;
        }
      }

      > a {
        @include skeleton;
      }

      input, .btn, select {
        @include skeleton;
      }

      textarea {
        resize: none;
        @include skeleton;
      }

      i {
        display: none;
      }
    }

    .withdrawal {
      @include skeleton;
    }

    .action button {
      @include skeleton;

      .img {
        visibility: hidden;
      }
    }

    > .wrapper .content {
      .channel {
        .title > span {
          @include skeleton;
        }

        > .setting {
          > .wrapper {
            .privacy, .privacy .switch > span, .privacy .switch > span::before {
              @include skeleton;
            }
          }
        }
      }
    }
  }
}
</style>